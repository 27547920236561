import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { TranslationProvider } from '@ubique-innovation/react-translations';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';

import './index.css';
import App, { IDP_REDIRECT_PATH } from './containers/App';

import translationsDe from './translations/de/translations.json';

const resources = {
    de: translationsDe,
};

const authorityUrl = process.env.REACT_APP_ISSUER_URL ? process.env.REACT_APP_ISSUER_URL : '/issuer';
const clientId = 'flesk-web';

const authConfig: AuthProviderProps = {
    authority: authorityUrl,
    client_id: clientId,
    redirect_uri: `${window.location.origin}${IDP_REDIRECT_PATH}`,
    response_mode: 'fragment',
    scope: 'openid profile',
    post_logout_redirect_uri: window.location.origin,
};

ReactDOM.render(
    <React.StrictMode>
        <TranslationProvider resources={resources} defaultLang="de">
            <BrowserRouter>
                <AuthProvider {...authConfig}>
                    <App />
                </AuthProvider>
            </BrowserRouter>
        </TranslationProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
