import React from 'react';
import * as styles from './TextInput.css';

const TextInput = ({
    label,
    value,
    onChange,
    required,
    name,
}: {
    label?: string;
    value?: string;
    required?: boolean;
    onChange: React.ChangeEventHandler;
    name?: string;
}): React.ReactElement => {
    return (
        <>
            {label != null && (
                <label htmlFor="input" className={styles.label}>
                    {label}
                    {required && label !== '' ? ' *' : ''}
                </label>
            )}

            <input
                className={required && value === '' ? styles.inputFieldText.required : styles.inputFieldText.standard}
                type="text"
                value={value}
                onChange={onChange}
                required={required}
                name={name}
            />
        </>
    );
};
TextInput.defaultProps = {
    label: '',
    value: '',
    name: undefined,
    required: false,
};
export default TextInput;
