import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useState } from 'react';
import CouponsOverviewTab from '../components/coupons/CouponsOverviewTab';
import CouponsTab from '../components/coupons/CouponsTab';
import Header from './Header';

import * as styles from './Workspaces.css';

enum Coupon {
    REDEEM,
    OVERVIEW,
}

const Coupons = (): React.ReactElement => {
    const [activeTab, setActiveTab] = useState(Coupon.REDEEM);
    const { t } = useTranslation();

    const tabs = [
        {
            title: t('coupons.redeem.tab'),
            onClick: () => setActiveTab(Coupon.REDEEM),
            active: activeTab === Coupon.REDEEM,
        },
        {
            title: t('coupons.overview'),
            onClick: () => setActiveTab(Coupon.OVERVIEW),
            active: activeTab === Coupon.OVERVIEW,
        },
    ];
    return (
        <>
            <Header headerTitle={t('navigation.coupons')} tabs={tabs} />
            <div className={styles.formDiv}>
                {activeTab === Coupon.REDEEM && <CouponsTab />}
                {activeTab === Coupon.OVERVIEW && <CouponsOverviewTab />}
            </div>
        </>
    );
};

export default Coupons;
