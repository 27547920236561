import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { CouponType } from '../../types/Company';
import LoadingIndicator from '../LoadingIndicator';
import FormControls from '../templates/FormControls';
import SectionTitle from '../templates/SectionTitle';
import TextInput from '../templates/TextInput';
import * as styles from './CouponsTab.css';

const CouponsTab = (): React.ReactElement => {
    const { t } = useTranslation();
    const auth = useAuth();
    const API = process.env.REACT_APP_API_URL || '';
    const [valid, setValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState<CouponType>({ couponCode: '', name: '' });

    const requestHeaders = useMemo(
        () => ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.user?.access_token}`,
        }),
        [auth.user?.access_token],
    );

    const onSave = useCallback(
        (coupon: CouponType) => {
            setLoading(true);

            const urlSearchParams = new URLSearchParams({ ...coupon });
            fetch(`${API}/v1/super/coupons/redeem?${urlSearchParams}`, { method: 'POST', headers: requestHeaders })
                .then((response) => {
                    if (response.ok) {
                        setLoading(false);
                        setFormState({ couponCode: '', name: '' });
                        return response.json();
                    }
                    if (response.status === 401 || response.status === 403) {
                        auth.signoutRedirect();
                    }
                    return Promise.reject();
                })
                .catch((error) => console.error(error));
        },
        [API, auth, requestHeaders],
    );

    const handleSubmit = (): void => {
        if (!valid) {
            alert(t('messages.error.invalidForm'));
            return;
        }
        onSave(formState);
    };

    const validateForm = (f: CouponType): boolean => f.couponCode !== '' && f.name !== '';

    useEffect(() => {
        setValid(validateForm(formState));
    }, [formState]);

    const setName = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const w = { ...formState };
            w.name = e.target.value;
            setFormState(w);
        },
        [formState],
    );

    const setCouponCode = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const w = { ...formState };
            w.couponCode = e.target.value;
            setFormState(w);
        },
        [formState],
    );

    useCallback(() => {
        const w = { ...formState };
        setFormState(w);
    }, [formState]);

    const couponForm = (
        <form id="coupons" className={styles.inputForm} onSubmit={handleSubmit}>
            <div className={styles.couponTitle}>{t('coupons.redeem.title')}</div>
            <SectionTitle title={t('coupons.name')} required />
            <TextInput value={formState.name} onChange={setName} />
            <SectionTitle title={t('coupons.couponCode')} required />
            <TextInput value={formState.couponCode} onChange={setCouponCode} />
        </form>
    );

    return (
        <>
            <div className={styles.wrapper}>{loading ? <LoadingIndicator /> : couponForm}</div>
            <FormControls id="coupons" formValid={valid} />
        </>
    );
};

export default CouponsTab;
