import * as styles from '../components/workspaces/WorkspaceOverviewTab.css';

export interface Tab {
    title: string;
    onClick: () => void;
    active: boolean;
}

export enum SortTab {
    WORKSPACE_ID,
    WORKSPACE_CODE,
    WORKSPACE_NAME,
    WORKSPACE_PUBLISHED,
    WORKSPACE_RESTRICTED,
    COMPANY_ID,
    COMPANY_NAME,
}

export const sortTabHeaderStyle = (activeSort: SortTab, sortTab: SortTab): string =>
    activeSort === sortTab ? styles.headerTitle.active : styles.headerTitle.inactive;
