import React from 'react';

import { useTranslation } from '@ubique-innovation/react-translations';
import * as styles from './FormControls.css';
import FleskButton from './FleskButton';

const FormControls = ({ formValid, id }: { formValid: boolean; id?: string }): React.ReactElement => {
    const { t } = useTranslation();
    return (
        <div className={styles.wrapper}>
            <FleskButton
                label={t('flesk.btn.save')}
                darkBtn
                id={id}
                submit
                disabled={!formValid}
                onClick={formValid ? undefined : () => alert(t('messages.error.invalidForm'))}
            />
        </div>
    );
};
FormControls.defaultProps = {
    id: '',
};

export default FormControls;
