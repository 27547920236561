import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useState } from 'react';
import WorkspacesOverviewTab from '../components/workspaces/WorkspacesOverviewTab';
import WorkspaceTab from '../components/workspaces/WorkspaceTab';
import Header from './Header';

import * as styles from './Workspaces.css';

enum Workspace {
    WORKSPACE,
    OVERVIEW,
}

const Workspaces = (): React.ReactElement => {
    const [activeTab, setActiveTab] = useState(Workspace.WORKSPACE);
    const { t } = useTranslation();

    const tabs = [
        {
            title: t('workspaces.create'),
            onClick: () => setActiveTab(Workspace.WORKSPACE),
            active: activeTab === Workspace.WORKSPACE,
        },
        {
            title: t('workspaces.overview'),
            onClick: () => setActiveTab(Workspace.OVERVIEW),
            active: activeTab === Workspace.OVERVIEW,
        },
    ];
    return (
        <>
            <Header headerTitle={t('navigation.workspaces')} tabs={tabs} />
            <div className={styles.formDiv}>
                {activeTab === Workspace.WORKSPACE && <WorkspaceTab />}
                {activeTab === Workspace.OVERVIEW && <WorkspacesOverviewTab />}
            </div>
        </>
    );
};

export default Workspaces;
