import React from 'react';
import { SortTab } from '../../types/Tabs';
import * as styles from './SortArrow.css';

const SortArrow = ({
    activeSort,
    sortTab,
    sort,
    asc,
}: {
    activeSort: SortTab;
    sortTab: SortTab;
    sort: () => void;
    asc: boolean;
}): React.ReactElement => {
    const ascOrDescActive = asc ? (
        <>
            <span className={styles.upArrow.active} />
            <span className={styles.downArrow.transparent} />
        </>
    ) : (
        <>
            <span className={styles.upArrow.transparent} />
            <span className={styles.downArrow.active} />
        </>
    );

    return (
        <>
            {activeSort !== sortTab ? (
                <div
                    className={styles.arrow}
                    role="button"
                    tabIndex={0}
                    onKeyDown={sort}
                    onClick={sort}
                    aria-label="sorting disabled"
                >
                    <span className={styles.upArrow.disabled} />
                    <span className={styles.downArrow.disabled} />
                </div>
            ) : (
                <div className={styles.arrow} role="button" tabIndex={0} onKeyDown={sort} onClick={sort}>
                    {ascOrDescActive}
                </div>
            )}
        </>
    );
};

export default SortArrow;
