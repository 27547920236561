import { useTranslation } from '@ubique-innovation/react-translations';
import { orderBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { CouponCreditCompany, CouponCreditCompanyResponse } from '../../types/Company';
import { SortTab, sortTabHeaderStyle } from '../../types/Tabs';
import LoadingIndicator from '../LoadingIndicator';
import SortArrow from '../templates/SortArrow';
import * as styles from './CouponsOverviewTab.css';
import { loadCouponCreditCompanies } from '../../services/SuperDataService';

const CouponsOverviewTab = (): React.ReactElement => {
    const auth = useAuth();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);

    const [couponCreditCompanies, setCouponCreditCompanies] = useState<CouponCreditCompany[]>();

    const [activeSort, setActiveSort] = useState(SortTab.COMPANY_ID);
    const [sortAsc, setSortAsc] = useState(true);

    const sortBy = (sortTab: SortTab, sortByFunc: (e: CouponCreditCompany) => string | number): void => {
        if (sortTab === activeSort) {
            const newSortAsc = !sortAsc;
            setCouponCreditCompanies(orderBy(couponCreditCompanies, sortByFunc, newSortAsc ? 'asc' : 'desc'));
            setSortAsc(newSortAsc);
        } else {
            setCouponCreditCompanies(orderBy(couponCreditCompanies, sortByFunc, 'asc'));
            setSortAsc(true);
            setActiveSort(sortTab);
        }
    };

    const sortByCompanyId = (): void => {
        sortBy(SortTab.COMPANY_ID, (e) => e.companyId);
    };

    const sortByCompanyName = (): void => {
        sortBy(SortTab.COMPANY_NAME, (e) => e.name);
    };

    useEffect(() => {
        setLoading(true);

        loadCouponCreditCompanies<CouponCreditCompanyResponse, CouponCreditCompany[]>(
            auth,
            ({ couponCreditCompanies: newCouponCreditCompanies }) => newCouponCreditCompanies,
        ).then((newCompanies) => {
            setCouponCreditCompanies(orderBy(newCompanies, (e) => e.companyId));
            setLoading(false);
        });
    }, [auth]);

    const couponsTable = (
        <div className={styles.table}>
            <header className={styles.header}>
                <div className={styles.colHeader}>
                    <div
                        className={sortTabHeaderStyle(activeSort, SortTab.COMPANY_ID)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={sortByCompanyId}
                        onClick={sortByCompanyId}
                    >
                        {t('companies.category.companyId')}{' '}
                    </div>
                    <SortArrow
                        activeSort={activeSort}
                        sortTab={SortTab.COMPANY_ID}
                        sort={sortByCompanyId}
                        asc={sortAsc}
                    />
                </div>
                <div className={styles.colHeader}>
                    <div
                        className={sortTabHeaderStyle(activeSort, SortTab.COMPANY_NAME)}
                        role="button"
                        tabIndex={0}
                        onKeyDown={sortByCompanyName}
                        onClick={sortByCompanyName}
                    >
                        {t('companies.name')}{' '}
                    </div>
                    <SortArrow
                        activeSort={activeSort}
                        sortTab={SortTab.COMPANY_NAME}
                        sort={sortByCompanyName}
                        asc={sortAsc}
                    />
                </div>
                <div className={styles.colHeader}>
                    <div>{t('companies.creditPaketId')} </div>
                </div>
                <div className={styles.colHeader}>
                    <div>{t('companies.creditsLeft')} </div>
                </div>
            </header>
            {couponCreditCompanies?.map((l) => (
                <div className={styles.row} key={l.companyId}>
                    <div className={styles.col}>{l.companyId}</div>
                    <div className={styles.col}>{l.name}</div>
                    <div className={styles.col}>{l.creditPaketId}</div>
                    <div className={styles.col}>{l.creditsLeft}</div>
                </div>
            ))}
        </div>
    );

    return <div className={styles.wrapper}>{loading ? <LoadingIndicator /> : couponsTable}</div>;
};

export default CouponsOverviewTab;
