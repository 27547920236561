import React, { useEffect, useState } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import uniqueId from 'lodash-es/uniqueId';
import findIndex from 'lodash-es/findIndex';
import * as styles from './Select.css';

export interface SelectOption {
    value: string;
    id: number;
}

const Select = ({
    label,
    options,
    value,
    onChange,
    inputChange,
}: {
    label?: string;
    options: SelectOption[];
    value: string;
    onChange: (v: number) => void;
    inputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}): React.ReactElement => {
    const [id] = useState(uniqueId('select-'));
    const [open, setOpen] = useState(false);
    const [selectIndex, setSelectIndex] = useState(0);
    useEffect(() => {
        setSelectIndex(findIndex(options, (o) => o.value === value));
    }, [options, value]);
    const { t } = useTranslation();
    // const onKeyDown = (e: React.KeyboardEvent): void => {
    //     e.preventDefault();
    //     const key = e.key !== undefined ? e.key : e.keyCode;

    //     if (key === 'Enter' || key === 13) {
    //         onChange(options[selectIndex].id);
    //         setOpen(false);
    //     } else if (key === 'ArrowDown' || key === 40) {
    //         setSelectIndex(clamp(selectIndex + 1, 0, options.length - 1));
    //         setOpen(true);
    //     } else if (key === 'ArrowUp' || key === 38) {
    //         setSelectIndex(clamp(selectIndex - 1, 0, options.length - 1));
    //         setOpen(true);
    //     }
    // };

    return (
        <>
            {label != null && <div className={styles.label}>{label}</div>}
            <div className={open ? styles.select.active : styles.select.standard}>
                <label htmlFor={id} className={styles.textfield}>
                    <input
                        id={`${id}input`}
                        value={value}
                        onFocus={() => setOpen(true)}
                        onBlur={() => setOpen(false)}
                        className={styles.input}
                        placeholder={t('super.dropdown.search')}
                        onChange={inputChange}
                    />
                    <ul className={open ? styles.selectDropdown.open : styles.selectDropdown.closed}>
                        {options.map((o, i) => {
                            let buttonClass = styles.buttonClass.default;
                            if (i === selectIndex) buttonClass = styles.buttonClass.selected;
                            if (o.value === value) buttonClass = styles.buttonClass.active;
                            return (
                                <li key={o ? o.id : ''} className={styles.li}>
                                    <button
                                        className={buttonClass}
                                        onClick={() => {
                                            onChange(o.id);
                                            setOpen(false);
                                        }}
                                        type="button"
                                        aria-label={o.value}
                                    >
                                        {o.value}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </label>
            </div>
        </>
    );
};

Select.defaultProps = {
    label: undefined,
};

export default Select;
