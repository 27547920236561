import React, { useState } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import Header from './Header';
import * as styles from './AutoOnboarding.css';
import AutoOnboardingTab from '../components/onboarding/AutoOnboardingTab';

enum AutoOnboardingTabState {
    ONBOARD,
}
const AutoOnboarding = (): React.ReactElement => {
    const [activeTab, setActiveTab] = useState(AutoOnboardingTabState.ONBOARD);
    const { t } = useTranslation();

    const tabs = [
        {
            title: t('navigation.autoonboarding'),
            onClick: () => setActiveTab(AutoOnboardingTabState.ONBOARD),
            active: activeTab === AutoOnboardingTabState.ONBOARD,
        },
    ];
    return (
        <>
            <Header headerTitle={t('navigation.autoonboarding')} tabs={tabs} />
            <div className={styles.formDiv}>
                {activeTab === AutoOnboardingTabState.ONBOARD && <AutoOnboardingTab />}
            </div>
        </>
    );
};

export default AutoOnboarding;
