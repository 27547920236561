import React from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

import * as styles from './Sidebar.css';
import FleskLogo from '../images/flesk-logo.svg';
import RobotIcon from '../images/sidebar/robot.svg';
import CompaniesIcon from '../images/sidebar/companies.svg';
import CouponsIcon from '../images/sidebar/coupons.svg';
import WorkspacesIcon from '../images/sidebar/workspaces.svg';
import LogoutIcon from '../images/sidebar/logout.svg';

import { Pathname } from '../types/Pathname';

const Navlink = (props: { text: string; href: Pathname; icon: string }): React.ReactElement => {
    const { text, href, icon } = props;
    const location = useLocation();
    return (
        <>
            <li className={styles.navListItem}>
                <Link
                    className={location.pathname === href ? styles.navLink.active : styles.navLink.standard}
                    to={href}
                >
                    <img className={styles.navIcon} src={icon} alt="" />
                    <div className={styles.navListDiv}>{text}</div>
                </Link>
            </li>
        </>
    );
};

const Sidebar = (): React.ReactElement => {
    const { t } = useTranslation();
    const auth = useAuth();

    return (
        <>
            <img className={styles.logo} src={FleskLogo} alt="" />
            <ul className={styles.sidebarUl}>
                <Navlink text={t('navigation.workspaces')} icon={WorkspacesIcon} href={Pathname.WORKSPACES} />
                <Navlink text={t('navigation.companies')} icon={CompaniesIcon} href={Pathname.COMPANIES} />
                <Navlink text={t('navigation.coupons')} icon={CouponsIcon} href={Pathname.COUPON} />
                <Navlink text={t('navigation.autoonboarding')} icon={RobotIcon} href={Pathname.AUTO_ONBOARDING} />
            </ul>
            <div className={styles.navContact} />
            <div className={styles.separateLine} />
            <div className={styles.logoutDiv}>
                <li className={styles.logout}>
                    <button className={styles.logoutLink} type="button" onClick={() => auth.signoutRedirect()}>
                        <img className={styles.navIcon} src={LogoutIcon} alt="" />
                        <div className={styles.navListDiv}>{t('navigation.logout')}</div>
                    </button>
                </li>
            </div>
        </>
    );
};

export default Sidebar;
