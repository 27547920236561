import { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';

const parseJwtPermissions: (base64Url: string) => string[] | string | undefined = (base64Url) => {
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => {
                return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
            })
            .join(''),
    );
    return JSON.parse(jsonPayload).permissions;
};

const usePermissions = (): string[] | undefined => {
    const [permissions, setPermissions] = useState<string[]>();
    const auth = useAuth();
    useEffect(() => {
        if (auth.user) {
            const base64Url = auth.user.access_token.split('.')[1];
            const jwtPermissions = parseJwtPermissions(base64Url);
            if (jwtPermissions != null) {
                if (Array.isArray(jwtPermissions)) {
                    setPermissions(jwtPermissions);
                } else {
                    setPermissions([jwtPermissions]);
                }
            }
        }
    }, [auth.user, auth.user?.access_token]);
    return permissions;
};

export default usePermissions;
