import React, { useCallback, useState } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import { isUndefined, uniqueId } from 'lodash-es';
import init, { get_onboardings, onboard, set_cell_config, set_request_paths, update_excel } from 'wasm-onboarding';
import { useAuth } from 'react-oidc-context';

import * as styles from './AutoOnboardingTab.css';
import SectionTitle from '../templates/SectionTitle';
import FleskButton from '../templates/FleskButton';

const API = process.env.REACT_APP_API_URL || '';
const USER_API = process.env.REACT_APP_USER_API_URL || '';
const WORKSPACE_BASE = process.env.REACT_APP_WORKSPACE_BASE_URL || '';

const AutoOnboardingTab = (): React.ReactElement => {
    const [isLoading, setIsLoading] = useState(Boolean);
    const [error, setError] = useState(undefined as string | undefined);
    const auth = useAuth();
    const { t } = useTranslation();
    const [initialized, setInitialized] = useState(false);
    const [excelUrl, setExcelUrl] = useState(undefined as string | undefined);

    if (!initialized) {
        init().then(() => {
            try {
                set_cell_config({
                    account_sheet_name: 'Accounts',
                    spaces_sheet_name: 'Spaces',
                    account_values_start: 4,
                    spaces_values_start: 4,
                    account_name_col: 1,
                    account_mail_col: 2,
                    account_first_name_col: 5,
                    account_last_name_col: 6,
                    activation_link_col: 9,
                    space_account_link_col: 1,
                    space_name_col: 2,
                    space_place_id_col: 3,
                    qr_code_link_col: 8,
                });

                set_request_paths(
                    {
                        company_list: '/v1/super/companies',
                        company_create: '/v1/super/companies/create',
                        workspace_list: '/v1/super/workspaces',
                        workspace_create: '/v1/super/workspaces/create',
                    },
                    {
                        add_coupon: '/v1/coupons/manage/add',
                    },
                );
            } catch (err) {
                if (typeof err === 'string') {
                    setError(err);
                } else if (err instanceof Object) {
                    setError(err.toString());
                } else {
                    console.error(err);
                    setError(`${err} occured`);
                }
            }
        });
        setInitialized(true);
    }
    if (!auth.isAuthenticated && !auth.isLoading) {
        auth.signinRedirect();
    }

    const onboardUser = useCallback(
        async (excel: Buffer): Promise<void> => {
            setError(undefined);
            try {
                if (auth.user) {
                    setIsLoading(true);
                    const excelBase64 = excel.toString('base64');
                    const onboardings = get_onboardings(excelBase64);
                    const onboardingResult = await onboard(API, USER_API, auth.user.access_token, onboardings);
                    const updatedExcel = update_excel(
                        excelBase64,
                        `${USER_API}/appl/accessapp/redeem/`,
                        WORKSPACE_BASE,
                        onboardingResult,
                    );
                    const updatedExcelBlob = new Blob([Buffer.from(updatedExcel, 'base64')], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });

                    const newExcelUrl = window.URL.createObjectURL(updatedExcelBlob);
                    setExcelUrl(newExcelUrl);
                    setIsLoading(false);
                    setError(undefined);
                }
            } catch (err) {
                if (typeof err === 'string') {
                    setError(err);
                } else if (err instanceof Object) {
                    setError(err.toString());
                } else {
                    console.error(err);
                    setError(`${err} occured`);
                }
                setIsLoading(false);
            }
        },
        [auth.user],
    );

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (e.target.files != null) {
            const excel = Buffer.from(await e.target.files[0].arrayBuffer());
            await onboardUser(excel);
        }
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>): Promise<void> => {
        e.preventDefault();
        for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
            if (e.dataTransfer.items[i].kind === 'file') {
                const f = e.dataTransfer.items[i].getAsFile();
                if (f != null) {
                    f.arrayBuffer().then((a) => onboardUser(Buffer.from(a)));
                    break;
                }
            }
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>): void => {
        e.preventDefault();
        // eslint-disable-next-line no-param-reassign
        e.dataTransfer.dropEffect = 'copy';
    };

    const id = uniqueId('file-upload-');
    return (
        <>
            <div className={styles.contentStyle} onDragOver={handleDragOver} onDrop={handleDrop}>
                <SectionTitle title={t('onboarding.section.title')} />
                <p>{t('onboarding.description')}</p>
                <div className={styles.wrapper} />
                <div className={styles.inputWrapper}>
                    <label htmlFor={id} className={styles.label}>
                        <input
                            type="file"
                            id={id}
                            className={styles.input}
                            onChange={handleChange}
                            key={id}
                            multiple
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />

                        <div>
                            <div className={styles.labelText}>{t('onboarding.actions.addExcel')}</div>
                        </div>
                    </label>
                </div>
                {!isUndefined(error) && <p className={styles.errorStyle}>{error}</p>}
                {isLoading && <p>{t('onboarding.isLoading')}</p>}
                {excelUrl && !isLoading && (
                    <div className={styles.buttonPadding}>
                        <FleskButton
                            darkBtn
                            onClick={() => {
                                const tmpButton = document.createElement('a');
                                tmpButton.href = excelUrl;
                                tmpButton.download = 'Onboarding_Liste_Flesk.xlsx';
                                document.body.appendChild(tmpButton);
                                tmpButton.click();
                                document.body.removeChild(tmpButton);
                            }}
                            label={t('onboarding.actions.downloadExcel')}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default AutoOnboardingTab;
