import React from 'react';
import * as styles from './Header.css';
import { Tab } from '../types/Tabs';

const Header = ({ headerTitle, tabs }: { headerTitle: string; tabs?: Tab[] }): React.ReactElement => {
    return (
        <div className={styles.headerDiv}>
            <h4 className={styles.headerTitle}> {headerTitle} </h4>
            {tabs != null && tabs.length > 0 && (
                <ul className={styles.ul}>
                    {tabs.map((tab) => (
                        <li key={tab.title} className={styles.tabUl}>
                            <button
                                className={tab.active ? styles.tabButton.active : styles.tabButton.standard}
                                type="button"
                                onClick={tab.onClick}
                            >
                                {tab.title}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
Header.defaultProps = { tabs: [] };

export default Header;
