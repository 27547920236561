import React from 'react';
import * as styles from './PublishToggle.css';

const PublishToggle = ({
    id,
    checked,
    onChange,
}: {
    id?: string;
    checked: boolean;
    onChange: React.ChangeEventHandler;
}): React.ReactElement => {
    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor={`toggle${id}`} className={styles.toggleControl}>
            <input
                id={`toggle${id}`}
                className={styles.toggleSwitch}
                type="checkbox"
                onChange={onChange}
                checked={checked}
            />
            <span className={styles.control} />
        </label>
    );
};
PublishToggle.defaultProps = {
    id: '',
};
export default PublishToggle;
